<template>
    <div id="app_header" class="app-header">
        <div class="app-container container-fluid d-flex align-items-stretch justify-content-between">
            <div class="app-header-logo d-flex align-items-center me-lg-9">
                <!--begin::Mobile toggle-->
                <div class="btn btn-icon btn-color-gray-500 btn-active-color-primary w-35px h-35px ms-n2 me-2 d-flex d-lg-none" id="kt_app_header_menu_toggle">
                    <i class="fa fa-bars"></i>
                </div>
                <!--end::Mobile toggle-->

                <!--begin::Logo image-->
                <a href="/">
                    <img src="../../assets/img/logo_kike_osborne.png" alt="Logo FBO Family" class="h-50px theme-light-show" />
                </a>
                <!--end::Logo image-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NavbarPublic",
};
</script>
