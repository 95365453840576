<template>
    <div id="app_header" class="app-header">
        <div class="app-container container-fluid d-flex align-items-stretch justify-content-between">
            <!--begin::Mobile toggle-->
            <div class="btn text-dark btn-active-color-primary d-flex align-items-center p-0 d-block d-lg-none" id="kt_app_header_menu_toggle">
                <button class="btn p-0 d-flex align-items-center" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenuMobile" aria-controls="offcanvasMenuMobile">
                    <i class="fa-duotone fa-bars-staggered fs-1 me-2"></i>
                    <small class="text-uppercase" style="font-size: 11px">Menú</small>
                </button>
            </div>
            <!--end::Mobile toggle-->
            <div class="app-header-logo d-flex align-items-center me-lg-9">
                <!--begin::Logo image-->
                <a href="/">
                    <img src="../../assets/img/logo_kike_osborne.png" alt="Logo FBO Family" class="theme-light-show logo" style="max-height: 70px;" />
                </a>
                <!--end::Logo image-->
            </div>

            <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1 ms-lg-5">
                <div class="d-flex align-items-stretch">
                    <div class="app-header-menu app-header-mobile-drawer align-items-stretch">
                        <div
                            class="menu menu-rounded menu-column menu-lg-row menu-active-bg menu-title-gray-600 menu-state-dark menu-arrow-gray-400 fw-semibold fw-semibold fs-6 align-items-stretch my-5 my-lg-0 px-2 px-lg-0">
                            <div class="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2">
                                <router-link to="/"><span class="menu-link">Inicio</span></router-link>
                            </div>
                            <div v-if="
                                user_role == 'superadmin' ||
                                user_role == 'admin'
                            " class="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2 dropdown">
                                <router-link to="/app"><span class="menu-link">App</span></router-link>
                                <div class="dropdown-menu dropdown-menu-right shadow-lg animated--grow-in" aria-labelledby="userDropdown">
                                    <router-link class="dropdown-item" to="/app/blog">
                                        Blog
                                    </router-link>
                                    <router-link class="dropdown-item" to="/app/courses">
                                        Cursos
                                    </router-link>
                                    <router-link class="dropdown-item" to="/app/forums">
                                        Foros
                                    </router-link>
                                </div>
                            </div>
                            <div v-if="
                                user_role == 'superadmin' ||
                                user_role == 'admin'
                            " class="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2">
                                <router-link to="/users"><span class="menu-link">Usuarios</span></router-link>
                            </div>
                            <div class="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2">
                                <router-link to="/membership/subscription"><span class="menu-link">Portal socios</span></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="app-navbar flex-shrink-0">
                    <div class="app-navbar-item ms-1 ms-lg-4 dropdown no-arrow">
                        <div class="btn btn-icon dropdown-toggle rounded bg-fbo h-md-40px w-md-40px" id="userDropdown" data-bs-toggle="dropdown" aria-expanded="false" style="cursor: pointer">
                            <div class="text-white text-uppercase">
                                {{
                                    Array.from(
                                        this.$store.state.user.data.username
                                    )[0]
                                }}
                            </div>
                        </div>
                        <!-- Dropdown - User Information -->
                        <div class="dropdown-menu dropdown-menu-right shadow-lg animated--grow-in p-2" aria-labelledby="userDropdown">
                            <div class="d-flex align-items-center px-3 mt-3 mb-4">
                                <div class="btn btn-icon rounded bg-fbo h-md-40px w-md-40px" id="userDropdown" data-bs-toggle="dropdown" aria-expanded="false" style="cursor: pointer">
                                    <div class="text-white text-uppercase">
                                        {{
                                            Array.from(
                                                this.$store.state.user.data
                                                    .username
                                            )[0]
                                        }}
                                    </div>
                                </div>
                                <div class="ps-3">
                                    <div class="fw-bold text-dark">
                                        {{
                                            this.$store.state.user.data
                                                .first_name +
                                            " " +
                                            this.$store.state.user.data
                                                .last_name
                                        }}
                                    </div>
                                    <div>
                                        {{ this.$store.state.user.data.email }}
                                    </div>
                                </div>
                            </div>
                            <router-link class="dropdown-item" to="/account/profile">
                                <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                Mi perfil
                            </router-link>
                            <router-link class="dropdown-item" to="/membership/subscription">
                                <i class="fa-sharp fa-solid fa-heart fa-fw mr-2 text-gray-400"></i>
                                Mi suscripción
                            </router-link>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#" @click="logOut()">
                                <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                Cerrar sesión
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="offcanvas offcanvas-start nav-mobile-menu" tabindex="-1" id="offcanvasMenuMobile">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title">Menú</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div class="d-flex align-items-stretch">
                        <div
                            class="menu menu-rounded menu-column menu-lg-row menu-active-bg menu-title-gray-600 menu-state-dark menu-arrow-gray-400 fw-semibold fw-semibold fs-6 align-items-stretch my-5 my-lg-0 px-2 px-lg-0">
                            <div class="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2">
                                <router-link to="/" @click="hideThisCanvas()"><span class="menu-link">Inicio</span></router-link>
                            </div>
                            <div v-if="user_role == 'admin'" class="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2 dropdown">
                                <router-link to="/app" @click="hideThisCanvas()"><span class="menu-link">App</span></router-link>
                                <div class="dropdown-menu dropdown-menu-right shadow-lg animated--grow-in" aria-labelledby="userDropdown">
                                    <router-link @click="hideThisCanvas()" class="dropdown-item" to="/app/blog">
                                        Blog
                                    </router-link>
                                    <router-link @click="hideThisCanvas()" class="dropdown-item" to="/app/courses">
                                        Cursos
                                    </router-link>
                                    <router-link @click="hideThisCanvas()" class="dropdown-item" to="/app/forums">
                                        Foros
                                    </router-link>
                                </div>
                            </div>
                            <div v-if="user_role == 'admin'" class="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2">
                                <router-link to="/users" @click="hideThisCanvas()"><span class="menu-link">Usuarios</span></router-link>
                            </div>
                            <div class="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2">
                                <router-link to="/membership/subscription" @click="hideThisCanvas()"><span class="menu-link">Portal socios</span></router-link>
                            </div>
                            <div class="ps-3">
                                <div data-kt-menu-trigger="click" class="menu-item">
                                    <!--begin:Menu link-->
                                    <router-link to="/membership/subscription" class="menu-link" @click="hideThisCanvas()">
                                        <span class="menu-icon position-absolute"><i class="fa-duotone fa-heart"></i></span><span class="menu-title ms-4">Mi suscripción</span>
                                    </router-link>
                                </div>
                                <div data-kt-menu-trigger="click" class="menu-item" v-if="
                                    user_role == 'superadmin' ||
                                    user_role == 'admin'
                                ">
                                    <!--begin:Menu link-->
                                    <router-link to="/membership/teams" class="menu-link" @click="hideThisCanvas()">
                                        <span class="menu-icon position-absolute"><i class="fa-duotone fa-objects-column"></i></span><span class="menu-title ms-4">Equipos</span>
                                    </router-link>
                                </div>
                                <div data-kt-menu-trigger="click" class="menu-item" v-if="
                                    user_role == 'teamManager' && !loading
                                ">
                                    <!--begin:Menu link-->
                                    <router-link :to="'/membership/teams/' + teams[0].UUID
                                        " class="menu-link" v-if="teams.length > 0" @click="hideThisCanvas()">
                                        <span class="menu-icon position-absolute"><i class="fa-duotone fa-objects-column"></i></span><span class="menu-title ms-4">Equipo:
                                            <span>{{
                                                teams[0].name
                                            }}</span></span>
                                    </router-link>
                                </div>
                                <div data-kt-menu-trigger="click" class="menu-item">
                                    <!--begin:Menu link-->
                                    <router-link to="/membership/members" class="menu-link" @click="hideThisCanvas()">
                                        <span class="menu-icon position-absolute"><i class="fa-duotone fa-users"></i></span><span class="menu-title ms-4">
                                            <span v-if="
                                                user_role == 'superadmin' ||
                                                user_role == 'admin'
                                            ">Socios</span>
                                            <span v-else>Mis socios</span>
                                        </span>
                                    </router-link>
                                </div>
                                <div data-kt-menu-trigger="click" class="menu-item" v-if="user_role == 'superadmin'">
                                    <!--begin:Menu link-->
                                    <router-link to="/membership/remittances" class="menu-link" @click="hideThisCanvas()">
                                        <span class="menu-icon position-absolute"><i class="fa-duotone fa-money-bill-transfer"></i></span><span class="menu-title ms-4">Remesas</span>
                                    </router-link>
                                </div>

                                <div data-kt-menu-trigger="click" class="menu-item" v-if="user_role == 'superadmin'">
                                    <!--begin:Menu link-->
                                    <router-link to="/membership/bank" class="menu-link" @click="hideThisCanvas()">
                                        <span class="menu-icon position-absolute"><i class="fa-duotone fa-wallet"></i></span><span class="menu-title ms-4">Tesorería</span>
                                    </router-link>
                                </div>
                            </div>

                            <button v-if="user.referrals.length > 0" class="d-sm-inline-block btn btn-fbo shadow-sm mt-3 mt-md-3 mb-3" data-bs-toggle="modal" data-bs-target="#modalNavAddMember">
                                <i class="fa-regular fa-plus"></i> Añadir socio
                            </button>

                            <!-- Modal Añadir Member-->
                            <div v-if="user.referrals.length > 0" class="modal fade" id="modalNavAddMember" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-header border-0">
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body px-md-5 pb-md-5 p-2 pt-0">
                                            <div class="text-center">
                                                <h3 class="mb-5">
                                                    Invitar socios
                                                </h3>
                                            </div>
                                            <h6 class="text-gray-800">
                                                Comparte tu
                                                <i>referral link</i> con tus
                                                amigos
                                            </h6>
                                            <ClipBoard :text="'https://socios.fbofamily.app/member/add/referral/' +
                                                user.referrals[0].referral
                                                " class="mb-5" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from "@/common/EventBus";
import userRoles from "@/mixins/user-roles.js";
import ClipBoard from "@/components/Global/ClipBoard.vue";

import MembershipService from "@/services/membership.service";

export default {
    name: "NavbarView",
    mixins: [userRoles],
    components: {
        ClipBoard,
    },
    computed: {
        currentUser() {
            return this.$store.state.user.data;
        },
    },
    data() {
        return {
            user: null,
            teams: null,
            loading: true,
        };
    },
    methods: {
        async start() {
            this.loading = true;
            this.user = this.$store.state.user.data;
            await this.getTeams();
            this.loading = false;
        },

        async getTeams() {
            var response = await MembershipService.getTeams();
            this.teams = response.data;
            // console.log(this.teams, "Teams");
        },

        hideThisCanvas() {
            let myOffcanvas = document.getElementById("offcanvasMenuMobile");
            let bsOffcanvas =
                window.bootstrap.Offcanvas.getInstance(myOffcanvas);
            bsOffcanvas.hide();
        },

        logOut() {
            this.$store.dispatch("user/logout");
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
        },
    },

    mounted() {
        EventBus.on("logout", () => {
            this.logOut();
        });
    },

    beforeUnmount() {
        EventBus.remove("logout");
    },

    created() {
        this.start();
    },
};
</script>
